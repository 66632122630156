import React, { useEffect } from "react";
import "../src/App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Navbar from "./Layout/Header/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";

import Home from "./Layout/home/Home";
// import Footer from "./Layout/Footer/Footer";
import Navbar2 from "./Layout/Header/Navbar2";
import Footer2 from "./Layout/Footer/Footer2";
import CaseStudyDetails from "./Component2/CaseStudies/CaseStudyDetails";
import CaseStudy from "./Component2/CaseStudies/CaseStudy";
import BlogDetails from "./Component2/Blogs/BlogDetails";
import Blog from "./Component2/Blogs/Blog";

function App() {
  useEffect(() => {
    AOS.init({ duration: 1800 });
  }, []);
  console.log("this is test ");
  return (
    <BrowserRouter>
      {/* <Navbar /> */}
      <Navbar2 />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/casestudydetails" element={<CaseStudyDetails />} />
        <Route path="/casestudy" element={<CaseStudy />} />
        <Route path="/BlogDetails" element={<BlogDetails />} />
        <Route path="/Blog" element={<Blog />} />
      </Routes>
      {/* <Footer /> */}
      <Footer2 />
    </BrowserRouter>
  );
}

export default App;
